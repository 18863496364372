<template>
	<ion-page>
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons>
					<ion-back-button :text="getBackButtonText()" default-href="/"></ion-back-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true">
			<form>
				<ion-grid>
					<ion-row>
						<ion-col size="12">
							<h1>Freier Auftrag</h1>

							<ion-list>
								<ion-item>
									<ion-label position="stacked">Auftraggebender Provider *</ion-label>
									<SearchModal
										title="Provider auswählen"
										:items="provider"
										@onInput="change('selectedProvider', $event)"
										:disabled="!provider || provider.length === 0"
									></SearchModal>
								</ion-item>
								<ion-item>
									<ion-label position="stacked">Kunde *</ion-label>
									<SearchModal
										title="Kunde auswählen"
										:items="customers"
										@onInput="change('selectedCustomer', $event)"
										:disabled="!customers || customers.length === 0"
									></SearchModal>
								</ion-item>
								<ion-item>
									<ion-label position="stacked">Filialgruppe *</ion-label>
									<SearchModal
										title="Filialgruppe auswählen"
										:items="storegroups"
										@onInput="change('selectedStoreGroup', $event)"
										:disabled="!storegroups || storegroups.length === 0"
									></SearchModal>
								</ion-item>
								<ion-item>
									<ion-label position="stacked">Material *</ion-label>
									<SearchModal
										title="Material auswählen"
										:items="materials"
										@onInput="change('selectedMaterial', $event)"
										:disabled="!materials || materials.length === 0"
									></SearchModal>
								</ion-item>
								<ion-item>
									<ion-label position="stacked">Filiale *</ion-label>
									<SearchModal
										cssclass="filli"
										title="Filiale auswählen"
										:items="stores"
										@onInput="change('selectedStore', $event)"
										:disabled="!stores || stores.length === 0"
									></SearchModal>
								</ion-item>
								<ion-item>
									<ion-label position="stacked">Fertigstellung bis *</ion-label>
									<ion-input type="date" required="true" @ionChange="setDate"></ion-input>
								</ion-item>
							</ion-list>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col>
							<ion-button color="primary" :disabled="!complete" @click="create">Auftrag speichern</ion-button>
						</ion-col>
					</ion-row>
				</ion-grid>
			</form>
		</ion-content>
	</ion-page>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRouter } from 'vue-router';
import { mapGetters, useStore } from 'vuex';
import {
	IonBackButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonGrid,
	IonRow,
	IonCol,
	IonItem,
	IonList,
	IonLabel,
	IonButton,
	IonInput

} from "@ionic/vue";

import SearchModal from '../components/SearchModal.vue';

export default defineComponent({
	name: "New",
	components: {
		IonBackButton,
		IonButtons,
		IonContent,
		IonHeader,
		IonPage,
		IonToolbar,
		IonGrid,
		IonRow,
		IonCol,
		IonItem,
		IonList,
		IonLabel,
		IonButton,
		IonInput,
		SearchModal
	},
	data() {
		return {
			getBackButtonText: () => {
				const win = window;
				const mode = win && win.Ionic && win.Ionic.mode;
				return mode === "ios" ? "zurück" : "zurück";
			},
		};
	},
	computed: {
		...mapGetters({
			provider: 'jobs/getProvider',
			customers: 'jobs/getCustomers',
			storegroups: 'jobs/getStoreGroups',
			materials: 'jobs/getMaterials',
			stores: 'jobs/getStores',
		}),
		complete() {
			return this.selectedProvider && this.selectedCustomer && this.selectedStoreGroup && this.selectedMaterial && this.selectedStore && this.selectedDate;
		}
	},
	setup() {
		const options = {
			cssClass: 'my-custom-interface'
		};

		const selectedProvider = ref(null);
		const selectedCustomer = ref(null);
		const selectedStoreGroup = ref(null);
		const selectedMaterial = ref(null);
		const selectedStore = ref(null);
		const selectedDate = ref(null);

		const store = useStore();
		const router = useRouter();
		if (store.getters.getSessionId) {
			store.dispatch('jobs/getProvider', store.getters.getSessionId);
			store.dispatch('jobs/getCustomers', store.getters.getSessionId);
			store.dispatch('jobs/getStoreGroups', store.getters.getSessionId);
		}
		return {
			store,
			router,
			options,
			selectedProvider,
			selectedCustomer,
			selectedStoreGroup,
			selectedMaterial,
			selectedStore,
			selectedDate
		};
	},
	methods: {
		change(prop, { detail: { value } }) {
			this[prop] = value;
			if (this.store.getters.getSessionId) {
				if (prop === 'selectedCustomer') {
					this.store.dispatch('jobs/getMateriels', {
						id: this.store.getters.getSessionId,
						customerId: value,
					});
				}
				if (prop === 'selectedStoreGroup') {
					this.store.dispatch('jobs/getStores', {
						id: this.store.getters.getSessionId,
						storeGroupId: value,
					});
				}
			}
		},
		setDate({ detail: { value } }) {
			this.selectedDate = value;
		},
		create() {

			this.store.dispatch('jobs/createJob', {
				userId: this.selectedProvider,
				customerId: this.selectedCustomer,
				storeGroupId: this.selectedStoreGroup,
				materialId: this.selectedMaterial,
				storeId: this.selectedStore,
				date: this.selectedDate
			})
				.then((id) => {
					if (id) {
						this.router.push({ name: 'Home' })
							.then(() => this.router.go(0));
					}
				});
		}
	}
});
</script>

<style>
.search-modal .item .sc-ion-label-md-h,
.search-modal .item .sc-ion-label-ios-h {
	white-space: normal;
	font-size: 0.85em;
	margin: 0.5rem;
}
.filli .item .sc-ion-label-md-h,
.filli .item .sc-ion-label-ios-h {
	height: 3.25em;
	contain: content;
	display: flex;
	align-items: center;
}
</style>
