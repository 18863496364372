import { createStore } from 'vuex';
import UrlTemplate from 'url-template';

import * as types from './types';
import { API_URL } from './helpers';

import jobs from './jobs';

const loginUrl = `${API_URL}{?params*}`;

const store = createStore({
	state: {
		isLoading: false,
		sessionId: null,
	},
	getters: {
		getSessionId: (state) => sessionStorage.getItem('PHPSESSID') || state.sessionId,
		getDekorateurId: (state) => sessionStorage.getItem('dekorateur') || state.dekorateur,
	},
	mutations: {
		[types.IS_LOADING](state) {
			state.isLoading = true;
		},
		[types.IS_NOT_LOADING](state) {
			state.isLoading = false;
		},
		[types.LOGIN_SUCCES](state, { sessionId, dekorateur }) {
			state.sessionId = sessionId;
			window.sessionStorage.setItem('PHPSESSID', sessionId);
			window.sessionStorage.setItem('dekorateur', dekorateur);
		}
	},
	actions: {
		login({ commit }, dekorateur) {
			const url = UrlTemplate.parse(loginUrl).expand({ params: { dekorateur }});

			return window.fetch(url)
				.then(response => {
					const sessionId = response.headers.get('PHPSESSID');
					commit(types.LOGIN_SUCCES, { sessionId, dekorateur });
					return Promise.resolve(sessionId);
				});
		}
	},
	modules: {
		jobs
	}
});
 
export default store;