<template>
	<ion-item-sliding>
		<ion-item
			v-if="message"
			:routerLink="'/message/' + message.id"
			:detail="false"
			class="list-item"
			:class="{
				'list-item__date--alert': overdue,
				'list-item__date--warning': nearDeadline
			}"
		>
			<ion-label class="ion-text-wrap grid-layout">
				<h2>{{ title }}</h2>
				<div class="list-item__infos">
					<ion-note
						class="list-item__date"
						:class="{
							'list-item__date--overdue': overdue
						}"
						v-if="notready"
					>
						<template v-if="overdue">
							<ion-icon :icon="alertCircle"></ion-icon>
						</template>
						<template v-else-if="nearDeadline">
							<ion-icon :icon="warningOutline"></ion-icon>
						</template>
						<template v-else>
							<ion-icon :icon="calendarOutline"></ion-icon>
						</template>
						{{ format(deadline, 'dd.MM.yy') }}
					</ion-note>
					<ion-note class="list-item__date" v-else>
						<ion-icon class="ion-icon--success" :icon="checkmarkCircle" />
						{{ message.fertig }}
					</ion-note>
					<ion-note class="list-item__date" v-if="message.vereinbarter_termin && notready">
						<ion-icon :icon="calendarNumberOutline" />
						{{ message.vereinbarter_termin }}
					</ion-note>
					<ion-note class="list-item__id"># {{ message.id }}</ion-note>
					<ion-note class="list-item__id" v-if="message.material_erhalten === '1'">
						<ion-icon :icon="checkboxOutline" />&nbsp;Material
					</ion-note>
					<!-- <ion-note class="list-item__id">
						<svg width="32" height="16" preserveAspectRatio="xMidYMid slice" viewBox="0 0 512 512">
							<circle
								:fill="message.fertig === '-2' ? '#777' : 'none'"
								stroke="#777"
								stroke-width="16"
								cx="96"
								cy="256"
								r="48"
							/>
							<circle
								:fill="message.fertig === '3' ? '#777' : 'none'"
								stroke="#777"
								stroke-width="16"
								cx="256"
								cy="256"
								r="48"
							/>
							<circle
								:fill="message.fertig === '-1' ? '#777' : 'none'"
								stroke="#777"
								stroke-width="16"
								cx="416"
								cy="256"
								r="48"
							/>
						</svg>
					</ion-note>-->
				</div>
				<p
					class="list-item__location"
				>{{ message.filiale }} ({{ message.strasse }}, {{ message.plz }} {{ message.ort }})</p>
				<ion-note color="medium" class="list-item__provider">{{ message.provider }}</ion-note>
				<ion-badge
					class="list-item__independent-order"
					color="secondary"
					v-if="message.freierauftrag !== '0'"
				>Freier Auftrag</ion-badge>
			</ion-label>
			<ion-icon class="ios-chevron" :icon="chevronForward" size="small" v-if="isIos()"></ion-icon>
		</ion-item>
		<ion-item-options side="end" v-if="message.freierauftrag === '1'">
			<ion-item-option color="danger" @click="setOpen(true)">Löschen</ion-item-option>
		</ion-item-options>
	</ion-item-sliding>
	<ion-alert
		:is-open="isOpenRef"
		header="Alert"
		sub-header="Subtitle"
		message="This is an alert message."
		css-class="my-custom-class"
		:buttons="buttons"
		@didDismiss="setOpen(false)"
	></ion-alert>
</template>

<script>
import {
	IonIcon,
	IonItem,
	IonItemOptions,
	IonItemOption,
	IonItemSliding,
	IonLabel,
	IonNote,
	IonBadge,
	IonAlert
} from "@ionic/vue";
import {
	chevronForward,
	calendarOutline,
	calendarNumberOutline,
	checkmarkCircle,
	checkboxOutline,
	alertCircle,
	warningOutline,
	ellipsisHorizontal
} from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { parse, format, differenceInBusinessDays } from 'date-fns';

export default defineComponent({
	name: "MessageListItem",
	components: {
		IonIcon,
		IonItem,
		IonItemOptions,
		IonItemOption,
		IonItemSliding,
		IonLabel,
		IonNote,
		IonBadge,
		IonAlert,

	},
	props: {
		message: Object,
	},
	methods: {
		isIos: () => {
			const win = window;
			return win && win.Ionic && win.Ionic.mode === "ios";
		},
	},
	data() {
		return {
			chevronForward,
			calendarOutline,
			calendarNumberOutline,
			checkmarkCircle,
			checkboxOutline,
			alertCircle,
			warningOutline,
			ellipsisHorizontal
		};
	},
	setup() {
		const isOpenRef = ref(false);
		const setOpen = (state) => (isOpenRef.value = state);
		const buttons = [
			{
				text: "Cancel",
				role: "cancel",
				cssClass: "secondary",
				handler: () => {
					console.log("Confirm Cancel");
				},
			},
			{
				text: "Ok",
				handler: () => {
					console.log("Confirm Ok");
				},
			},
		];

		return { buttons, isOpenRef, setOpen, format };
	},
	computed: {
		notready() {
			return ['-1', '1', '-2', '2', '-3', '3', '4', '-4'].includes(this.message.fertig);
		},
		deadline() {
			return parse(this.message.deadline, 'dd.MM.yy', new Date());
		},
		overdue() {
			return Date.now() >= this.message.deadline_timestamp && this.notready;
		},
		nearDeadline() {
			return differenceInBusinessDays(
				new Date(),
				this.deadline
			) >= -3 && this.notready && !this.overdue;
		},
		title() {
			return this.message.bezeichnung.replace('Freier Auftrag:', '');
		}
	}
});
</script>

<style scoped>
.grid-layout {
	display: grid;
	gap: 0.25rem 0.75rem;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;
	grid-template-areas:
		"title    infos"
		"location infos"
		"provider independent";
	width: 100%;
}

.list-item h2 {
	font-weight: 600;
	margin: 0;
	grid-area: title;
}

.list-item__date--warning {
	--ion-item-background: rgba(var(--ion-color-warning-rgb), 0.1);
}
.list-item__date--alert {
	--ion-item-background: rgba(var(--ion-color-danger-rgb), 0.1);
}

.list-item__infos {
	grid-area: infos;
}

.list-item__date {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	align-self: baseline;
	gap: 0.25rem;
	color: #777;
	font-variant-numeric: tabular-nums;
	letter-spacing: -0.05em;
}

.list-item__date--overdue {
	font-weight: bold;
	color: var(--ion-color-danger);
}

.list-item__id {
	color: #777;
	font-weight: 400;
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	align-self: baseline;
}

.list-item__independent-order {
	grid-area: independent;
	align-self: baseline;
}

.list-item__location {
	grid-area: location;
}

.list-item__provider {
	grid-area: provider;
}

.ios-chevron {
	margin-top: 0.75rem;
	align-self: baseline;
	color: var(--ion-color-medium);
}

.ion-icon--success {
	color: var(--ion-color-success);
}

.ion-icon--alert {
	color: var(--ion-color-danger);
}

.ion-icon--warnig {
	color: var(--ion-color-warning);
}
</style>
