<template>
	<ion-content class="ion-padding" scrollY="false">
		<ion-list>
			<ion-item @click="() => router.push('/new')">Freien Auftrag hinzufügen</ion-item>
		</ion-list>
	</ion-content>
</template>


<script>
import { IonContent, IonList, IonItem } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'NavPopover',
	components: { IonContent, IonList, IonItem },
	setup() {
		const router = useRouter();
		return { router };
	},
});
</script>

<style>
.my-custom-class {
	--width: 20rem;
}

.my-custom-class .popover-content {
	overflow-y: hidden;
}
</style>
