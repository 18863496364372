import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '../store';

import Home from '../views/Home.vue';
import New from '../views/New.vue';

const routes = [
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'Home',
		component: Home
	},
	{
		name: 'Single',
		path: '/message/:id',
		component: () => import('../views/ViewMessage.vue')
	},
	{
		path: '/new',
		name: 'New',
		component: New
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	if(
		(!store.getters.getDekorateurId && to.query.dekorateur)
		|| 
		(to.query.dekorateur && store.getters.getDekorateurId && store.getters.getDekorateurId !== to.query.dekorateur)) {
		console.log('Login', to.query.dekorateur, store.getters.getDekorateurId);
		store.dispatch('login', to.query.dekorateur)
			.then(() => next());
	}
	else {
		next();
	}
});


export default router;
